var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-alert", { attrs: { variant: "danger", show: false } }, [
        _c("h4", { staticClass: "alert-heading" }, [
          _vm._v("Error fetching user data"),
        ]),
        _c(
          "div",
          { staticClass: "alert-body" },
          [
            _vm._v(" No user found with this user id. Check "),
            _c(
              "b-link",
              {
                staticClass: "alert-link",
                attrs: { to: { name: "apps-users-list" } },
              },
              [_vm._v(" User List ")]
            ),
            _vm._v(" for other users. "),
          ],
          1
        ),
      ]),
      [
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { cols: "12", xl: "9", lg: "8", md: "7" } },
              [
                _c(
                  "b-card",
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          {
                            staticClass:
                              "d-flex justify-content-between flex-column",
                            attrs: { cols: "21", xl: "7" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-content-start" },
                              [
                                _c("b-avatar", {
                                  staticClass: "d-none d-sm-block mr-1",
                                  attrs: {
                                    text: "GMS",
                                    variant: "light-primary",
                                    size: "104px",
                                    rounded: "",
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-column" },
                                  [
                                    _c("div", { staticClass: "mb-1" }, [
                                      _c("h4", { staticClass: "mb-0" }, [
                                        _vm._v(" Gabriel Moraes Sodré "),
                                      ]),
                                      _c("span", { staticClass: "card-text" }, [
                                        _vm._v("gabriel@gmail.com"),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-wrap" },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              to: {
                                                name: "customer-edit",
                                                params: { uuid: 1 },
                                              },
                                              variant: "outline-primary",
                                            },
                                          },
                                          [_vm._v(" Editar ")]
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "ml-1",
                                            attrs: {
                                              variant: "outline-danger",
                                            },
                                          },
                                          [_vm._v(" Excluir ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center mt-2" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c(
                                      "b-avatar",
                                      {
                                        attrs: {
                                          variant: "light-info",
                                          rounded: "",
                                        },
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "DollarSignIcon",
                                            size: "18",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "ml-1" }, [
                                      _c("h5", { staticClass: "mb-0" }, [
                                        _vm._v(" R$ 300,40 "),
                                      ]),
                                      _c("small", [_vm._v("Em aberto")]),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-items-center ml-1",
                                  },
                                  [
                                    _c(
                                      "b-avatar",
                                      {
                                        attrs: {
                                          variant: "light-success",
                                          rounded: "",
                                        },
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "TrendingUpIcon",
                                            size: "18",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "ml-1" }, [
                                      _c("h5", { staticClass: "mb-0" }, [
                                        _vm._v(" R$ 22.150,20 "),
                                      ]),
                                      _c("small", [_vm._v("Aprovado")]),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c("b-col", { attrs: { cols: "12", xl: "5" } }, [
                          _c("ul", { staticClass: "list-unstyled my-1" }, [
                            _c("li", { staticClass: "pt-1 pb-0" }, [
                              _vm._v(" CPF "),
                              _c("span", { staticClass: "float-right" }, [
                                _vm._v(" 000.000.000-00 "),
                              ]),
                            ]),
                            _c("li", { staticClass: "pt-1 pb-0" }, [
                              _vm._v(" WhatsApp "),
                              _c("span", { staticClass: "float-right" }, [
                                _vm._v(" (00) 0000-0000 "),
                              ]),
                            ]),
                            _c("li", { staticClass: "pt-1 pb-0" }, [
                              _vm._v(" Pedidos "),
                              _c(
                                "span",
                                { staticClass: "float-right" },
                                [
                                  _c(
                                    "b-badge",
                                    { attrs: { variant: "light-info" } },
                                    [_vm._v(" 15 ")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("li", { staticClass: "pt-1 pb-0" }, [
                              _vm._v(" Cadastrado "),
                              _c("span", { staticClass: "float-right" }, [
                                _vm._v(" 01/01/2021 "),
                              ]),
                            ]),
                            _c("li", { staticClass: "pt-1 pb-0" }, [
                              _vm._v(" E-mail marketing "),
                              _c(
                                "span",
                                { staticClass: "float-right " },
                                [
                                  _c(
                                    "b-badge",
                                    { attrs: { variant: "light-danger" } },
                                    [_vm._v("Não aceita")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-col",
              { attrs: { cols: "12", md: "5", xl: "3", lg: "4" } },
              [
                _c(
                  "b-card",
                  { staticClass: "border-primary", attrs: { "no-body": "" } },
                  [
                    _c(
                      "b-card-header",
                      { staticClass: "card-header pt-1 pb-1 mb-0" },
                      [
                        _c(
                          "h5",
                          { staticClass: "text-primary text-center mb-0" },
                          [_vm._v(" Carrinho ")]
                        ),
                      ]
                    ),
                    _c(
                      "b-card-body",
                      [
                        _c(
                          "ul",
                          { staticClass: "list-unstyled my-1 text-center" },
                          [
                            _c("li", [
                              _c("span", { staticClass: "align-middle" }, [
                                _vm._v("5 produtos"),
                              ]),
                            ]),
                            _c("li", { staticClass: "pt-1 pb-0" }, [
                              _c("h4", { staticClass: "align-middle" }, [
                                _c("strong", [_vm._v(" R$ 10.542,01 ")]),
                              ]),
                              _c(
                                "small",
                                { staticClass: "text-center d-block" },
                                [_vm._v("Total")]
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "ripple",
                                rawName: "v-ripple.400",
                                value: "rgba(40, 199, 111, 0.15)",
                                expression: "'rgba(40, 199, 111, 0.15)'",
                                modifiers: { 400: true },
                              },
                            ],
                            staticClass: "btn-block",
                            attrs: { variant: "outline-primary" },
                            on: {
                              click: function ($event) {
                                return _vm.showToast("success")
                              },
                            },
                          },
                          [_vm._v(" Copiar link ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-tabs",
          { attrs: { pills: "" } },
          [
            _c(
              "b-tab",
              {
                attrs: { active: "" },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _c("feather-icon", {
                          staticClass: "mr-0 mr-sm-50",
                          attrs: { icon: "DollarSignIcon", size: "16" },
                        }),
                        _c("span", { staticClass: "d-none d-sm-inline" }, [
                          _vm._v("Pedidos"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c(
                  "b-card",
                  { attrs: { "no-body": "" } },
                  [
                    _c("b-table", {
                      ref: "refCartListTable",
                      staticClass: "position-relative",
                      attrs: {
                        items: _vm.itemsOrders,
                        responsive: "",
                        fields: _vm.fieldsOrders,
                        "primary-key": "id",
                        "show-empty": "",
                        "empty-text": "No matching records found",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "cell(title)",
                          fn: function (data) {
                            return [
                              _c(
                                "b-media",
                                {
                                  attrs: { "vertical-align": "center" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "aside",
                                        fn: function () {
                                          return [
                                            data.item.image
                                              ? _c("b-avatar", {
                                                  attrs: {
                                                    size: "32",
                                                    src: data.item.image,
                                                  },
                                                })
                                              : _c(
                                                  "b-avatar",
                                                  {
                                                    attrs: {
                                                      rounded: "sm",
                                                      variant:
                                                        "light-secondary",
                                                    },
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      attrs: {
                                                        icon: "ImageIcon",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "d-block text-nowrap" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(data.item.title) + " "
                                      ),
                                    ]
                                  ),
                                  _c("small", [
                                    _vm._v("Variante: "),
                                    _c("strong", [
                                      _vm._v(_vm._s(data.item.variant)),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(price)",
                          fn: function (data) {
                            return [_vm._v(" " + _vm._s(data.item.price) + " ")]
                          },
                        },
                        {
                          key: "cell(action)",
                          fn: function (data) {
                            return [
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12" },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "cursor-pointer cursor mx-1",
                                      attrs: {
                                        id:
                                          "order-" +
                                          data.item.id +
                                          "-preview-icon",
                                        icon: "EyeIcon",
                                        size: "16",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push({
                                            name: "apps-invoice-preview",
                                            params: { id: data.item.id },
                                          })
                                        },
                                      },
                                    }),
                                    _c("b-tooltip", {
                                      attrs: {
                                        title: "Detalhes do pedido",
                                        target:
                                          "order-" +
                                          data.item.id +
                                          "-preview-icon",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-tab",
              {
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _c("feather-icon", {
                          staticClass: "mr-0 mr-sm-50",
                          attrs: { icon: "ShoppingCartIcon", size: "16" },
                        }),
                        _c("span", { staticClass: "d-none d-sm-inline" }, [
                          _vm._v("Carrinho"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c(
                  "b-card",
                  { attrs: { "no-body": "" } },
                  [
                    _c("b-table", {
                      ref: "refCartListTable",
                      staticClass: "position-relative",
                      attrs: {
                        items: _vm.itemsCart,
                        responsive: "",
                        fields: _vm.fieldsCart,
                        "primary-key": "id",
                        "show-empty": "",
                        "empty-text": "No matching records found",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "cell(title)",
                          fn: function (data) {
                            return [
                              _c(
                                "b-media",
                                {
                                  attrs: { "vertical-align": "center" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "aside",
                                        fn: function () {
                                          return [
                                            data.item.image
                                              ? _c("b-avatar", {
                                                  attrs: {
                                                    size: "32",
                                                    src: data.item.image,
                                                  },
                                                })
                                              : _c(
                                                  "b-avatar",
                                                  {
                                                    attrs: {
                                                      rounded: "sm",
                                                      variant:
                                                        "light-secondary",
                                                    },
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      attrs: {
                                                        icon: "ImageIcon",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "d-block text-nowrap" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(data.item.title) + " "
                                      ),
                                    ]
                                  ),
                                  _c("small", [
                                    _vm._v("Variante: "),
                                    _c("strong", [
                                      _vm._v(_vm._s(data.item.variant)),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(price)",
                          fn: function (data) {
                            return [_vm._v(" " + _vm._s(data.item.price) + " ")]
                          },
                        },
                        {
                          key: "cell(action)",
                          fn: function (data) {
                            return [
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12" },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "cursor-pointer cursor mx-1",
                                      attrs: {
                                        id:
                                          "product-" +
                                          data.item.id +
                                          "-preview-icon",
                                        icon: "EyeIcon",
                                        size: "16",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push({
                                            name: "apps-invoice-preview",
                                            params: { id: data.item.id },
                                          })
                                        },
                                      },
                                    }),
                                    _c("b-tooltip", {
                                      attrs: {
                                        title: "Pág. do produto",
                                        target:
                                          "product-" +
                                          data.item.id +
                                          "-preview-icon",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-tab",
              {
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _c("feather-icon", {
                          staticClass: "mr-0 mr-sm-50",
                          attrs: { icon: "InfoIcon", size: "16" },
                        }),
                        _c("span", { staticClass: "d-none d-sm-inline" }, [
                          _vm._v("Endereço"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c(
                  "b-card",
                  {
                    staticClass: "card-developer-meetup",
                    attrs: { "no-body": "" },
                  },
                  [
                    _c(
                      "b-card-body",
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "meetup-header d-flex align-items-center",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "my-auto" },
                              [
                                _c("b-card-title", { staticClass: "mb-25" }, [
                                  _vm._v(" Endereços utilizados "),
                                ]),
                                _c("b-card-text", { staticClass: "mb-0" }, [
                                  _vm._v(
                                    " Listagem de endereço utilizados nas compras "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "b-media",
                          { attrs: { "no-body": "" } },
                          [
                            _c(
                              "b-media-aside",
                              { staticClass: "mr-1" },
                              [
                                _c(
                                  "b-avatar",
                                  {
                                    attrs: {
                                      rounded: "",
                                      variant: "light-primary",
                                      size: "34",
                                    },
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "MapPinIcon", size: "18" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("b-media-body", [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-8" }, [
                                  _c("h6", { staticClass: "mb-0" }, [
                                    _vm._v(
                                      " Av. Professor Florestan Fernandes, 1036 "
                                    ),
                                  ]),
                                  _c("small", [
                                    _vm._v(
                                      "Camboinhas, Niterói/RJ - 24358-580"
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col-4 text-right" }, [
                                  _c("p", [
                                    _c("strong", { staticClass: "d-block" }, [
                                      _c(
                                        "a",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover.top",
                                              value: "Visualizar pedido",
                                              expression: "'Visualizar pedido'",
                                              modifiers: {
                                                hover: true,
                                                top: true,
                                              },
                                            },
                                          ],
                                          staticClass: "btn-link",
                                          attrs: { href: "#" },
                                        },
                                        [_vm._v(" #0001 ")]
                                      ),
                                    ]),
                                    _vm._v(" 01/01/2021 "),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "b-media",
                          { attrs: { "no-body": "" } },
                          [
                            _c(
                              "b-media-aside",
                              { staticClass: "mr-1" },
                              [
                                _c(
                                  "b-avatar",
                                  {
                                    attrs: {
                                      rounded: "",
                                      variant: "light-primary",
                                      size: "34",
                                    },
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "MapPinIcon", size: "18" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("b-media-body", [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-8" }, [
                                  _c("h6", { staticClass: "mb-0" }, [
                                    _vm._v(" Av. Gavião Peixoto, 145 "),
                                  ]),
                                  _c("small", [
                                    _vm._v("Icaraí, Niterói/RJ - 24358-580"),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col-4 text-right" }, [
                                  _c("p", [
                                    _c("strong", { staticClass: "d-block" }, [
                                      _c(
                                        "a",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover.top",
                                              value: "Visualizar pedido",
                                              expression: "'Visualizar pedido'",
                                              modifiers: {
                                                hover: true,
                                                top: true,
                                              },
                                            },
                                          ],
                                          staticClass: "btn-link",
                                          attrs: { href: "#" },
                                        },
                                        [_vm._v(" #0002 ")]
                                      ),
                                    ]),
                                    _vm._v(" 18/03/2021 "),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-tab",
              {
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _c("feather-icon", {
                          staticClass: "mr-0 mr-sm-50",
                          attrs: { icon: "ThumbsUpIcon", size: "16" },
                        }),
                        _c("span", { staticClass: "d-none d-sm-inline" }, [
                          _vm._v("Likes"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c(
                  "b-card",
                  { attrs: { "no-body": "" } },
                  [
                    _c("b-table", {
                      ref: "refCartListTable",
                      staticClass: "position-relative",
                      attrs: {
                        items: _vm.itemsLike,
                        responsive: "",
                        fields: _vm.fieldslike,
                        "primary-key": "id",
                        "show-empty": "",
                        "empty-text": "No matching records found",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "cell(title)",
                          fn: function (data) {
                            return [
                              _c(
                                "b-media",
                                {
                                  attrs: { "vertical-align": "center" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "aside",
                                        fn: function () {
                                          return [
                                            data.item.image
                                              ? _c("b-avatar", {
                                                  attrs: {
                                                    size: "32",
                                                    src: data.item.image,
                                                  },
                                                })
                                              : _c(
                                                  "b-avatar",
                                                  {
                                                    attrs: {
                                                      rounded: "sm",
                                                      variant:
                                                        "light-secondary",
                                                    },
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      attrs: {
                                                        icon: "ImageIcon",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "d-block text-nowrap" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(data.item.title) + " "
                                      ),
                                    ]
                                  ),
                                  _c("small", [
                                    _vm._v("Variante: "),
                                    _c("strong", [
                                      _vm._v(_vm._s(data.item.variant)),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(price)",
                          fn: function (data) {
                            return [_vm._v(" " + _vm._s(data.item.price) + " ")]
                          },
                        },
                        {
                          key: "cell(action)",
                          fn: function (data) {
                            return [
                              _c("div", { staticClass: "form-row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        target: "_blank",
                                        href: data.item.url,
                                      },
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass:
                                          "cursor-pointer cursor mx-1",
                                        attrs: {
                                          icon: "ExternalLinkIcon",
                                          size: "16",
                                        },
                                      }),
                                      _c("b-tooltip", {
                                        attrs: {
                                          title: "Pág. do produto",
                                          target:
                                            "product-" +
                                            data.item.id +
                                            "-preview-icon",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-tab",
              {
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _c("feather-icon", {
                          staticClass: "mr-0 mr-sm-50",
                          attrs: { icon: "FileTextIcon", size: "16" },
                        }),
                        _c("span", { staticClass: "d-none d-sm-inline" }, [
                          _vm._v("Anotações"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c(
                  "b-card",
                  { attrs: { title: "Histórico de anotações" } },
                  [
                    _c(
                      "app-timeline",
                      { staticClass: "mt-2" },
                      [
                        _c(
                          "app-timeline-item",
                          { attrs: { icon: "MapPinIcon" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0",
                              },
                              [
                                _c("h6", [
                                  _vm._v("Alteração do endereço de entrega"),
                                ]),
                                _c("small", { staticClass: "text-dark" }, [
                                  _vm._v("01/01/2021 às 15:20"),
                                ]),
                              ]
                            ),
                            _c("p", { staticClass: "mt-1" }, [
                              _vm._v(
                                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "app-timeline-item",
                          {
                            attrs: {
                              variant: "warning",
                              icon: "DollarSignIcon",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0",
                              },
                              [
                                _c("h6", [
                                  _vm._v("Solicitou alteração do produto"),
                                ]),
                                _c("small", { staticClass: "text-dark" }, [
                                  _vm._v("11/02/2021 às 15:20"),
                                ]),
                              ]
                            ),
                            _c("p", { staticClass: "mt-1" }, [
                              _vm._v(
                                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }