<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="false">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template>
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <b-card>
            <b-row>
              <!-- User Info: Left col -->
              <b-col
                cols="21"
                xl="7"
                class="d-flex justify-content-between flex-column"
              >
                <!-- User Avatar & Action Buttons -->
                <div class="d-flex justify-content-start">
                  <b-avatar
                    text="GMS"
                    variant="light-primary"
                    size="104px"
                    class="d-none d-sm-block mr-1"
                    rounded
                  />
                  <div class="d-flex flex-column">
                    <div class="mb-1">
                      <h4 class="mb-0">
                        Gabriel Moraes Sodré
                      </h4>
                      <span class="card-text">gabriel@gmail.com</span>
                    </div>
                    <div class="d-flex flex-wrap">
                      <b-button
                        :to="{ name: 'customer-edit', params: { uuid: 1 } }"
                        variant="outline-primary"
                      >
                        Editar
                      </b-button>
                      <b-button
                        variant="outline-danger"
                        class="ml-1"
                      >
                        Excluir
                      </b-button>
                    </div>
                  </div>
                </div>

                <!-- User Stats -->
                <div class="d-flex align-items-center mt-2">
                  <div class="d-flex align-items-center">
                    <b-avatar
                      variant="light-info"
                      rounded
                    >
                      <feather-icon
                        icon="DollarSignIcon"
                        size="18"
                      />
                    </b-avatar>
                    <div class="ml-1">
                      <h5 class="mb-0">
                        R$ 300,40
                      </h5>
                      <small>Em aberto</small>
                    </div>
                  </div>

                  <div class="d-flex align-items-center ml-1">
                    <b-avatar
                      variant="light-success"
                      rounded
                    >
                      <feather-icon
                        icon="TrendingUpIcon"
                        size="18"
                      />
                    </b-avatar>
                    <div class="ml-1">
                      <h5 class="mb-0">
                        R$ 22.150,20
                      </h5>
                      <small>Aprovado</small>
                    </div>
                  </div>
                </div>
              </b-col>
              <!-- Right Col: Table -->
              <b-col
                cols="12"
                xl="5"
              >
                <ul class="list-unstyled my-1">
                  <li class="pt-1 pb-0">
                    CPF
                    <span class="float-right">
                      000.000.000-00
                    </span>
                  </li>
                  <li class="pt-1 pb-0">
                    WhatsApp
                    <span class="float-right">
                      (00) 0000-0000
                    </span>
                  </li>
                  <li class="pt-1 pb-0">
                    Pedidos
                    <span class="float-right">
                      <b-badge variant="light-info">
                        15
                      </b-badge>
                    </span>
                  </li>
                  <li class="pt-1 pb-0">
                    Cadastrado
                    <span class="float-right">
                      01/01/2021
                    </span>
                  </li>
                  <li class="pt-1 pb-0">
                    E-mail marketing
                    <span class="float-right ">
                      <b-badge variant="light-danger">Não aceita</b-badge>
                    </span>
                  </li>
                </ul>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <b-card
            no-body
            class="border-primary"
          >
            <b-card-header class="card-header pt-1 pb-1 mb-0">
              <h5 class="text-primary text-center mb-0">
                Carrinho
              </h5>
            </b-card-header>

            <b-card-body>
              <ul class="list-unstyled my-1 text-center">
                <li>
                  <span class="align-middle">5 produtos</span>
                </li>
                <li class="pt-1 pb-0">
                  <h4 class="align-middle">
                    <strong>
                      R$ 10.542,01
                    </strong>
                  </h4>
                  <small class="text-center d-block">Total</small>
                </li>
              </ul>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="outline-primary"
                class="btn-block"
                @click="showToast('success')"
              >
                Copiar link
              </b-button>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-tabs
        pills
      >
        <b-tab active>
          <template #title>
            <feather-icon
              icon="DollarSignIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Pedidos</span>
          </template>
          <b-card
            no-body
          >
            <b-table
              ref="refCartListTable"
              :items="itemsOrders"
              responsive
              :fields="fieldsOrders"
              primary-key="id"
              show-empty
              empty-text="No matching records found"
              class="position-relative"
            >
              <template #cell(title)="data">
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      v-if="data.item.image"
                      size="32"
                      :src="data.item.image"
                    />
                    <b-avatar v-else rounded="sm" variant="light-secondary">
                      <feather-icon icon="ImageIcon" />
                    </b-avatar>
                  </template>
                  <span class="d-block text-nowrap">
                    {{ data.item.title }}
                  </span>
                  <small>Variante: <strong>{{ data.item.variant }}</strong></small>
                </b-media>
              </template>

              <template #cell(price)="data">
                {{ data.item.price }}
              </template>

              <template #cell(action)="data">
                <div class="form-row">
                  <div class="col-12">
                    <feather-icon
                      :id="`order-${data.item.id}-preview-icon`"
                      icon="EyeIcon"
                      size="16"
                      class="cursor-pointer cursor mx-1"
                      @click="$router.push({ name: 'apps-invoice-preview', params: { id: data.item.id }})"
                    />
                    <b-tooltip
                      title="Detalhes do pedido"
                      :target="`order-${data.item.id}-preview-icon`"
                    />
                  </div>
                </div>
              </template>
            </b-table>
          </b-card>
        </b-tab>

         <b-tab>
          <template #title>
            <feather-icon
              icon="ShoppingCartIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Carrinho</span>
          </template>
          <b-card
            no-body
          >
            <b-table
              ref="refCartListTable"
              :items="itemsCart"
              responsive
              :fields="fieldsCart"
              primary-key="id"
              show-empty
              empty-text="No matching records found"
              class="position-relative"
            >
              <template #cell(title)="data">
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      v-if="data.item.image"
                      size="32"
                      :src="data.item.image"
                    />
                    <b-avatar v-else rounded="sm" variant="light-secondary">
                      <feather-icon icon="ImageIcon" />
                    </b-avatar>
                  </template>
                  <span class="d-block text-nowrap">
                    {{ data.item.title }}
                  </span>
                  <small>Variante: <strong>{{ data.item.variant }}</strong></small>
                </b-media>
              </template>

              <template #cell(price)="data">
                {{ data.item.price }}
              </template>

              <template #cell(action)="data">
                <div class="form-row">
                  <div class="col-12">
                    <feather-icon
                      :id="`product-${data.item.id}-preview-icon`"
                      icon="EyeIcon"
                      size="16"
                      class="cursor-pointer cursor mx-1"
                      @click="$router.push({ name: 'apps-invoice-preview', params: { id: data.item.id }})"
                    />
                    <b-tooltip
                      title="Pág. do produto"
                      :target="`product-${data.item.id}-preview-icon`"
                    />
                  </div>
                </div>
              </template>
            </b-table>
          </b-card>
        </b-tab>

        <!-- Tab: Information -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Endereço</span>
          </template>
          <b-card
            no-body
            class="card-developer-meetup"
          >
            <b-card-body>
              <!-- metting header -->
              <div class="meetup-header d-flex align-items-center">
                <div class="my-auto">
                  <b-card-title class="mb-25">
                    Endereços utilizados
                  </b-card-title>
                  <b-card-text class="mb-0">
                    Listagem de endereço utilizados nas compras
                  </b-card-text>
                </div>
              </div>
              <b-media
                no-body
              >
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="MapPinIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <div class="row">
                    <div class="col-8">
                      <h6 class="mb-0">
                        Av. Professor Florestan Fernandes, 1036
                      </h6>
                      <small>Camboinhas, Niterói/RJ - 24358-580</small>
                    </div>
                    <div class="col-4 text-right">
                      <p>
                        <strong class="d-block">
                          <a v-b-tooltip.hover.top="'Visualizar pedido'" class="btn-link" href="#">
                            #0001
                          </a>
                        </strong>
                        01/01/2021
                      </p>
                    </div>
                  </div>
                </b-media-body>
              </b-media>
              <b-media
                no-body
              >
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="MapPinIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <div class="row">
                    <div class="col-8">
                      <h6 class="mb-0">
                        Av. Gavião Peixoto, 145
                      </h6>
                      <small>Icaraí, Niterói/RJ - 24358-580</small>
                    </div>
                    <div class="col-4 text-right">
                      <p>
                        <strong class="d-block">
                          <a v-b-tooltip.hover.top="'Visualizar pedido'" class="btn-link" href="#">
                            #0002
                          </a>
                        </strong>
                        18/03/2021
                      </p>
                    </div>
                  </div>
                </b-media-body>
              </b-media>
            </b-card-body>
          </b-card>
        </b-tab>

        <!--b-tab>
          <template #title>
            <feather-icon
              icon="HeartIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Lista de desejos</span>
          </template>
          aaa
        </b-tab-->

        <b-tab>
          <template #title>
            <feather-icon
              icon="ThumbsUpIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Likes</span>
          </template>
          <b-card
            no-body
          >
            <b-table
              ref="refCartListTable"
              :items="itemsLike"
              responsive
              :fields="fieldslike"
              primary-key="id"
              show-empty
              empty-text="No matching records found"
              class="position-relative"
            >
              <template #cell(title)="data">
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      v-if="data.item.image"
                      size="32"
                      :src="data.item.image"
                    />
                    <b-avatar v-else rounded="sm" variant="light-secondary">
                      <feather-icon icon="ImageIcon" />
                    </b-avatar>
                  </template>
                  <span class="d-block text-nowrap">
                    {{ data.item.title }}
                  </span>
                  <small>Variante: <strong>{{ data.item.variant }}</strong></small>
                </b-media>
              </template>

              <template #cell(price)="data">
                {{ data.item.price }}
              </template>

              <template #cell(action)="data">
                <div class="form-row">
                  <div class="col-12">
                    <a target="_blank" :href="data.item.url">
                      <feather-icon
                        icon="ExternalLinkIcon"
                        size="16"
                        class="cursor-pointer cursor mx-1"
                      />
                      <b-tooltip
                        title="Pág. do produto"
                        :target="`product-${data.item.id}-preview-icon`"
                      />
                    </a>
                  </div>
                </div>
              </template>
            </b-table>
          </b-card>
        </b-tab>

        <!-- Tab: Social -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="FileTextIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Anotações</span>
          </template>
          <b-card title="Histórico de anotações">
            <app-timeline class="mt-2">
              <app-timeline-item icon="MapPinIcon">
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h6>Alteração do endereço de entrega</h6>
                  <small class="text-dark">01/01/2021 às 15:20</small>
                </div>
                <p class="mt-1">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
              </app-timeline-item>
              <app-timeline-item  variant="warning" icon="DollarSignIcon">
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h6>Solicitou alteração do produto</h6>
                  <small class="text-dark">11/02/2021 às 15:20</small>
                </div>
                <p class="mt-1">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
              </app-timeline-item>
            </app-timeline>
          </b-card>
        </b-tab>
      </b-tabs>
    </template>
  </div>
</template>

<script>
import {
  BRow, BCol, BAlert, BLink, BCard, BAvatar, BButton, BCardHeader, BCardBody, BTable, BTooltip, BMedia, BMediaAside,
  BBadge, BTab, BTabs, BCardText, BCardTitle, BMediaBody, VBTooltip,
} from 'bootstrap-vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BCardText,
    BMediaBody,
    BCardTitle,
    BMediaAside,
    BRow,
    BCardHeader,
    BTable,
    BTab,
    BTabs,
    BBadge,
    BCardBody,
    BAvatar,
    BCol,
    BAlert,
    BLink,
    BCard,
    BButton,
    BMedia,
    BTooltip,
  },
  methods: {
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Link copiado',
          icon: 'BellIcon',
          text: 'Link do carrinho copiado! É só enviar para o cliente.',
          variant,
        },
      });
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 1,
      rows: 200,
      fieldsCart: [
        {
          key: 'title',
          label: 'Produto',
          sortable: true,
        },
        {
          key: 'price',
          label: 'Preço',
          class: 'text-center',
          sortable: true,
        },
        {
          key: 'date',
          label: 'Curtido em',
          class: 'text-center',
          sortable: true,
        },
        {
          key: 'action',
          label: '',
          class: 'text-center',
          thStyle: 'width: 50px',
        },
      ],
      itemsCart: [
        {
          id: 3232,
          title: 'Smartwatch Relógio Eletrônico Crystal 2',
          image: 'https://cdn.shopify.com/s/files/1/0434/2176/1693/products/41-01aacdc20576c0d46915506813657874-640-0_50x50.jpg',
          status: true,
          action: '',
          relevance: 5,
          price: 'R$ 100,00',
          variant: 'Vermelho',
          date: '10/11/2021 12:01',
        },
        {
          id: 5656,
          title: 'Smartwatch Relógio Eletrônico Crystal 2',
          image: false,
          status: true,
          action: '',
          relevance: 3,
          price: 'R$ 100,00',
          variant: 'Azul',
          date: '10/11/2021 12:01',
        },
        {
          id: 6464,
          title: 'Smartwatch Relógio Eletrônico Crystal 2',
          image: 'https://cdn.shopify.com/s/files/1/0434/2176/1693/products/colorido-ip68-x2-al-m-do-esporte-da-aptid-o-prova-d-gua-rel-gio-inteligente-jpg_640x640-11-0160463e1cbc6a289315384915208707-640-0_50x50.jpg',
          status: false,
          action: '',
          relevance: 3,
          price: 'R$ 100,00',
          variant: '',
          date: '10/11/2021 12:01',
        },
        {
          id: 2144,
          title: 'Smartwatch Relógio Eletrônico Crystal 2',
          image: 'https://cdn.shopify.com/s/files/1/0434/2176/1693/products/a0a14bd9-366a-4ba5-8417-8ec780adf3f7_50x50.jpg',
          status: true,
          action: '',
          relevance: 3,
          price: 'R$ 100,00',
          variant: '',
          date: '10/11/2021 12:01',
        },
        {
          id: 6543,
          title: 'Smartwatch Relógio Eletrônico Crystal 2',
          image: 'https://cdn.shopify.com/s/files/1/0434/2176/1693/products/429b3f7cb01-1c6cecf0e1c207983a15487023386893-640-0_50x50.jpg',
          status: true,
          action: '',
          relevance: 3,
          price: 'R$ 100,00',
          variant: 'Cinza',
          date: '10/11/2021 12:01',
        },
      ],
      fieldsOrders: [
        {
          key: 'id',
          label: 'Pedido',
          sortable: true,
        },
        {
          key: 'title',
          label: 'Produto',
          sortable: true,
        },
        {
          key: 'price',
          label: 'Preço',
          class: 'text-center',
          sortable: true,
        },
        {
          key: 'date',
          label: 'Data',
          class: 'text-center',
          sortable: true,
        },
        {
          key: 'action',
          label: '',
          class: 'text-center',
          thStyle: 'width: 50px',
        },
      ],
      itemsOrders: [
        {
          id: 3232,
          title: 'Smartwatch Relógio Eletrônico Crystal 2',
          image: 'https://cdn.shopify.com/s/files/1/0434/2176/1693/products/41-01aacdc20576c0d46915506813657874-640-0_50x50.jpg',
          status: true,
          action: '',
          relevance: 5,
          price: 'R$ 100,00',
          variant: 'Vermelho',
          date: '10/11/2021 12:01',
        },
        {
          id: 5656,
          title: 'Smartwatch Relógio Eletrônico Crystal 2',
          image: false,
          status: true,
          action: '',
          relevance: 3,
          price: 'R$ 100,00',
          variant: 'Azul',
          date: '10/11/2021 12:01',
        },
      ],
      fieldslike: [
        {
          key: 'title',
          label: 'Produto',
          sortable: true,
        },
        {
          key: 'price',
          label: 'Preço',
          class: 'text-center',
          sortable: true,
        },
        {
          key: 'date',
          label: 'Adicionado em',
          class: 'text-center',
          sortable: true,
        },
        {
          key: 'action',
          label: '',
          class: 'text-center',
          thStyle: 'width: 50px',
        },
      ],
      itemsLike: [
        {
          id: 3232,
          title: 'Smartwatch Relógio Eletrônico Crystal 2',
          image: 'https://cdn.shopify.com/s/files/1/0434/2176/1693/products/41-01aacdc20576c0d46915506813657874-640-0_50x50.jpg',
          status: true,
          action: '',
          relevance: 5,
          price: 'R$ 100,00',
          variant: 'Vermelho',
          date: '10/11/2021 12:01',
          url: 'https://google.com.br',
        },
      ],
    };
  },
};
</script>
